import React from "react";
import styled from "@emotion/styled";
import {
  PRIORITY_COLOR,
  PRIORITY_COLOR_DEFAULT,
  PRIORITY_NAME,
} from "./constants";

const ArticlePriorityWrapper = styled<any>("div")`
  background: ${({ background }) => background};
  display: flex;
  justify-content: center;
  padding: 5px;
  align-items: center;
  position: relative;
  width: 24px;
  flex-shrink: 0;
`;

const ArticlePriorityText = styled("div")`
  transform: rotate(-90deg);
  color: white;
  cursor: default;
  white-space: nowrap;
  font-size: 1.1em;
  text-transform: uppercase;
`;

export const PriorityIndicator = ({ priority }) => {
  return (
    <ArticlePriorityWrapper
      background={PRIORITY_COLOR[priority] || PRIORITY_COLOR_DEFAULT}
    >
      <ArticlePriorityText>{PRIORITY_NAME[priority] || "(TBD)"}</ArticlePriorityText>
    </ArticlePriorityWrapper>
  );
};
