import { useData } from "../DataContext";
import { AddSourceGroupForm } from "./AddSourceGroupForm";
import { useAuth } from "../AuthContext";
import { SourceGroup } from "./SourceGroup";
import { SourceStatus } from "../interfaces";
import { StyledSelect } from "./StyledSelect";
import styled from "@emotion/styled";
import { useState } from "react";
import { Button, ButtonRow } from "../Button";
import FocusTrap from "focus-trap-react";
import { postDeleteSourceGroups, postCombineSourceGroups } from "../fetchers";

const STATUS_LABEL = {
  [SourceStatus.Created]: "Pendiente de asignar",
  [SourceStatus.Assigned]: "Asignadas",
  [SourceStatus.Done]: "Hechas",
  [SourceStatus.Deleted]: "Eliminadas",
};

const StatusForm = () => {
  const { setStatus, status } = useData();

  return (
    <div>
      <StyledSelect
        value={status}
        onChange={(evt) => setStatus(evt.target.value as SourceStatus)}
        style={{ borderColor: "black", fontSize: "1.2em", background: "white" }}
      >
        {Object.values(SourceStatus)
          .filter((s) => s !== SourceStatus.Merged)
          .map((s) => (
            <option key={s} value={s}>
              Mostrar: {STATUS_LABEL[s]}
            </option>
          ))}
      </StyledSelect>
    </div>
  );
};

const ModalOverlay = styled("a")({
  position: "fixed",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  zIndex: 10000,
  padding: "16px",
  backdropFilter: "blur(5px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const ModalStyle = styled("div")({
  background: "white",
  maxHeight: "100%",
  overflow: "scroll",
  border: "1px solid #999",

  padding: "32px",
  width: "1000px",
  maxWidth: "100%",
});

const ModalSourceGroup = ({ sourceGroup, isSelected, onSelect }) => {
  const { comments, sources } = sourceGroup;
  const { image } = sources[0] || {};

  return (
    <button
      onClick={onSelect}
      style={{
        display: "flex",
        padding: "8px",
        alignItems: "center",
        border: "1px solid #999",
        marginBottom: "8px",
        width: "100%",
        textAlign: "left",
        ...(isSelected
          ? {
              background: "rgba(77, 175, 255, 0.3)",
            }
          : {}),
      }}
    >
      <div>
        <img
          src={image}
          style={{
            width: "100px",
            height: "50px",
            display: "block",
            objectFit: "cover",
          }}
        />
      </div>
      <div>
        {!!comments && <div style={{ marginLeft: "12px" }}>{comments}</div>}
        {!!sources.length && (
          <ul style={{ marginTop: "4px" }}>
            {sources.map((source) => (
              <li>{source.title}</li>
            ))}
          </ul>
        )}
      </div>
    </button>
  );
};

const Modal = ({ isOpen, close }) => {
  const { user } = useAuth();
  const [selected, setSelected] = useState({});
  const { sourceGroups, refresh } = useData();

  if (!isOpen) {
    return null;
  }

  const extractIds = () =>
    Object.entries(selected)
      .filter(([_, isActive]) => isActive)
      .map(([id]) => id);

  const onDelete = async () => {
    await postDeleteSourceGroups(user, extractIds());
    refresh();
    setSelected({});
  };

  const onCombine = async () => {
    await postCombineSourceGroups(user, extractIds());
    refresh();
    setSelected({});
  };

  return (
    <FocusTrap>
      <div style={{}}>
        <ModalOverlay onClick={close}>
          <ModalStyle onClick={(evt) => evt.stopPropagation()}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h2>Edición rápida</h2>
              <Button onClick={close}>X</Button>
            </div>
            <div>
              {sourceGroups?.map((sourceGroup) => (
                <ModalSourceGroup
                  sourceGroup={sourceGroup}
                  isSelected={!!selected[sourceGroup.id]}
                  onSelect={() =>
                    setSelected((prev) => ({
                      ...prev,
                      [sourceGroup.id]: !prev[sourceGroup.id],
                    }))
                  }
                />
              ))}
            </div>
            <ButtonRow style={{ marginTop: "16px" }}>
              <Button success onClick={onCombine}>
                Combinar noticias
              </Button>
              <Button danger onClick={onDelete}>
                Eliminar noticias
              </Button>
            </ButtonRow>
          </ModalStyle>
        </ModalOverlay>
      </div>
    </FocusTrap>
  );
};

const SourcesDashboard = () => {
  const { user } = useAuth();
  const { sourceGroups, users } = useData();
  const [isOpen, setOpen] = useState(false);

  return (
    <div
      style={{
        background: "#ddd",
        padding: "16px",
      }}
    >
      <Modal isOpen={isOpen} close={() => setOpen(false)}></Modal>

      <div style={{ display: "flex" }}>
        <StatusForm />
        <div style={{ marginRight: "16px" }}></div>
        <Button onClick={() => setOpen(true)}>Edición rápida</Button>
      </div>

      <hr style={{ margin: "16px 0" }} />
      {!!users &&
        sourceGroups?.map((sourceGroup) => (
          <SourceGroup key={sourceGroup.id} sourceGroup={sourceGroup} />
        ))}
      {process.env.NODE_ENV === "development" && (
        <code style={{ fontSize: "0.7em", overflow: "hidden" }}>
          <pre>
            {JSON.stringify(user, null, 2)}
            {JSON.stringify(users, null, 2)}
            {JSON.stringify(sourceGroups, null, 2)}
          </pre>
        </code>
      )}
    </div>
  );
};

export const HomePage = () => (
  <div>
    <AddSourceGroupForm />
    <SourcesDashboard />
  </div>
);
