import { useState } from "react";
import { useData } from "../DataContext";
import { postCreateSourceGroup } from "../fetchers";
import { Button } from "../Button";

export const AddSourceGroupForm = () => {
  const { refresh } = useData();
  const [comments, setComments] = useState("");
  const [priority, setPriority] = useState("");
  const [sourceUrl, setSourceUrl] = useState("");

  const handleSubmit = async () => {
    await postCreateSourceGroup({
      comments,
      sourceUrl,
      priority,
    });
    setComments("");
    setSourceUrl("");
    setPriority("");
    refresh();
  };

  return (
    <div style={{ padding: "8px 16px 32px" }}>
      <h2>Añadir una publicación</h2>
      <div>
        <select
          value={priority}
          onChange={(evt) => setPriority(evt.target.value)}
        >
          <option>Prioridad: (TBD)</option>
          <option value="LOW">Prioridad: BAJA 👀</option>
          <option value="MID">Prioridad: MEDIA 🔔</option>
          <option value="HIGH">Prioridad: ALTA 🚨</option>
          <option value="URGENT">Prioridad: URGENTE 🚨🚨🚨</option>
        </select>
      </div>
      <div>
        <input
          style={{
            margin: "8px 0",
            width: "500px",
            maxWidth: "100%",
            padding: "8px",
          }}
          type="text"
          name="sourceUrl"
          placeholder="URL de la fuente"
          value={sourceUrl}
          onChange={(evt) => setSourceUrl(evt.target.value)}
        />
      </div>
      <div>
        <textarea
          name="comments"
          placeholder="Comentarios..."
          value={comments}
          onChange={(evt) => setComments(evt.target.value)}
          style={{
            width: "500px",
            maxWidth: "100%",
            padding: "8px",
          }}
        />
      </div>

      <Button
        onClick={handleSubmit}
        style={{
          marginTop: "16px",
        }}
      >
        Añadir&nbsp;&nbsp;📬
      </Button>
    </div>
  );
};
