import React, { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { Button } from "../Button";
import { Spacer } from "../Spacer";
import TextareaAutosize from "react-textarea-autosize";
import { postUpdateSourceGroup } from "../fetchers";
import { useAuth } from "../AuthContext";

const TextArea = styled(TextareaAutosize)(({ disabled, readOnly }) => ({
  padding: "8px 12px",
  fontSize: "1.1em",
  fontWeight: disabled || readOnly ? "bold" : null,

  borderRadius: "3px",
  border: "2px solid rgba(0, 0, 0, 0.125)",
  background: "white",
  resize: "vertical",
  width: "100%",

  ...(disabled || readOnly
    ? {
        background: "#eee",
      }
    : ""),
}));

export const SourceGroupComments = ({
  sourceGroupId,
  comments: initialComments = "",
}) => {
  const { user } = useAuth();

  const [latestSavedComments, setLatestSavedComments] = useState(
    initialComments
  );
  const [comments, setComments] = useState(initialComments);
  const [isEditing, setEditing] = useState(false);

  const textarea = useRef(null);

  // Focus when edit mode is activated
  useEffect(() => {
    if (isEditing) {
      textarea.current.focus();
      // setLatestComments(comments);
    }
  }, [isEditing]);

  return (
    <div>
      {(isEditing || !!comments) && (
        <TextArea
          readOnly={!isEditing}
          ref={textarea}
          onChange={(evt) => setComments(evt.target.value)}
          value={comments}
        />
      )}
      <Spacer h={4} />
      <div
        style={{
          display: "flex",
          justifyContent: isEditing || !!comments ? "flex-end" : null,
        }}
      >
        {isEditing ? (
          <>
            <Button
              small
              inverse
              onClick={() => {
                setComments(latestSavedComments);
                setEditing(false);
              }}
            >
              Cancelar
            </Button>
            <Spacer w={8} />
            <Button
              small
              onClick={() => {
                setEditing(false);
                setLatestSavedComments(comments);

                postUpdateSourceGroup(user, {
                  id: sourceGroupId,
                  data: {
                    comments,
                  },
                });
              }}
            >
              Guardar cambios
            </Button>
          </>
        ) : (
          <Button
            small
            inverse
            onClick={() => {
              setEditing(true);
            }}
          >
            Editar comentarios
          </Button>
        )}
      </div>
    </div>
  );
};
