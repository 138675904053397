import styled from "@emotion/styled";

const StyledSelectBase = styled("select")({
  borderRadius: 0,
  border: "3px solid red",
  padding: "4px 30px 4px 8px",
  WebkitAppearance: "none",
  MozAppearance: "none",
  background: "#EEE",
  fontWeight: 500,

  "+ span": {
    position: "absolute",
    right: "2px",
    top: "1px",
    pointerEvents: "none",
  },
});

export const StyledSelect = ({ icon = "🔽", ...props }) => {
  return (
    <span style={{ position: "relative", cursor: "pointer" }}>
      <StyledSelectBase {...props} />
      <span>{icon}</span>
    </span>
  );
};
