import { useData } from "../DataContext";
import { timeSince } from "../date";
import { useAuth } from "../AuthContext";
import { Sources } from "./Sources";
import styled from "@emotion/styled";

import { PriorityIndicator } from "../ArticlePriority";
import {
  PRIORITY,
  PRIORITY_NAME,
  FINAL_SOURCE_STATUS,
  PRIORITY_COLOR,
  PRIORITY_COLOR_DEFAULT,
} from "../constants";
import { postUpdateSourceGroup } from "../fetchers";
import { SourceStatus } from "../interfaces";
import { StyledSelect } from "./StyledSelect";
import { Button } from "../Button";
import { SourceGroupComments } from "./SourceGroupComments";
import { Spacer } from "../Spacer";

const Card = styled("div")({
  background: "white",
  marginBottom: "16px",
  transition: "box-shadow 100ms ease-out",
  border: "1px solid #555",

  "&:hover": {
    boxShadow: "0px 0px 10px rgba(0,0,0,.3)",
  },
});

const PriorityForm = ({ sourceGroupId: id, priority, borderColor }) => {
  const { user } = useAuth();
  const { refresh } = useData();

  const handlePriorityChange = async (newPriority) => {
    await postUpdateSourceGroup(user, { id, data: { priority: newPriority } });
    refresh();
  };

  return (
    <div>
      <StyledSelect
        value={priority || ""}
        onChange={(evt) => handlePriorityChange(evt.target.value)}
        style={{
          borderColor,
        }}
      >
        {Object.keys(PRIORITY).map((pr) => (
          <option key={pr} value={pr}>
            📟: {PRIORITY_NAME[pr] || "()"}
          </option>
        ))}
        <option value="">📟: TBD</option>
      </StyledSelect>
    </div>
  );
};

const AssignForm = ({ sourceGroupId: id, assignedUserId, borderColor }) => {
  const { user } = useAuth();
  const { refresh, users } = useData();

  const handleAssignedChange = async (assignedUserId = null) => {
    await postUpdateSourceGroup(user, {
      id,
      data: { assignedUserId },
    });
    refresh();
  };

  return (
    <div>
      <StyledSelect
        value={assignedUserId || ""}
        onChange={(evt) => handleAssignedChange(evt.target.value)}
        style={{
          borderColor,
        }}
      >
        <option value="">🧑‍💻: TBD</option>
        <option value={user.id}>🧑‍💻: yo ({user.name})</option>

        {users
          .filter((u) => u.id !== user.id)
          .map(({ id, name }) => (
            <option key={id} value={id}>
              🧑‍💻: {name}
            </option>
          ))}
      </StyledSelect>
    </div>
  );
};

const AssignUserButton = ({ sourceGroupId: id, unassignInstead = false }) => {
  const { user } = useAuth();
  const { refresh, setStatus } = useData();

  const assignUser = async () => {
    await postUpdateSourceGroup(user, {
      id,
      data: { assignedUserId: unassignInstead ? null : user.id },
    });
    if (!unassignInstead) {
      setStatus(SourceStatus.Assigned);
      window.scroll(0, 0);
    }
    refresh();
  };

  return (
    <Button onClick={assignUser} inverse={unassignInstead}>
      {unassignInstead ? "Desasignar ☹️" : "Me la quedo"}
    </Button>
  );
};

const SourceGroupBar = styled("div")({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  margin: "-4px",

  "> *": {
    margin: "4px",
  },
});

export const SourceGroup = ({ sourceGroup }) => {
  const { user } = useAuth();
  const { refresh } = useData();
  const {
    id,
    comments,
    status,
    sources = [],
    priority,
    originalTime,
    assignedUserId,
  } = sourceGroup;

  const handleDelete = () => {
    postUpdateSourceGroup(user, {
      id,
      data: {
        status: SourceStatus.Deleted,
      },
    }).then(refresh);
  };

  const handleDone = () => {
    postUpdateSourceGroup(user, {
      id,
      data: {
        status: SourceStatus.Done,
      },
    }).then(refresh);
  };

  const priorityColor = PRIORITY_COLOR[priority] || PRIORITY_COLOR_DEFAULT;

  return (
    <Card style={{ display: "flex" }}>
      <PriorityIndicator priority={priority} />
      <div
        style={{
          padding: "16px",
          flexGrow: 1,
        }}
      >
        <SourceGroupBar>
          <div
            style={{
              fontSize: "1.1em",
              background: priorityColor,
              color: "white",
              padding: "8px",
              lineHeight: "1em",
            }}
          >
            🕑
            <span
              style={
                {
                  // textDecoration: "underline",
                }
              }
            >
              {timeSince(originalTime)}
            </span>
          </div>
          <div style={{}}>
            <PriorityForm
              priority={priority}
              sourceGroupId={id}
              borderColor={priorityColor}
            />
          </div>
          <div style={{}}>
            <AssignForm
              assignedUserId={assignedUserId}
              sourceGroupId={id}
              borderColor={priorityColor}
            ></AssignForm>
          </div>
          <div style={{ fontSize: ".8em", color: "#999" }}>{id}</div>
        </SourceGroupBar>

        <Spacer h={16} />

        <SourceGroupComments sourceGroupId={id} comments={comments} />

        <div
          style={{
            marginTop: "8px",
          }}
        >
          <Sources
            sourceGroupId={id}
            sources={sources}
            isEditable={!FINAL_SOURCE_STATUS.has(status)}
          />
        </div>

        <SourceGroupBar>
          {FINAL_SOURCE_STATUS.has(status) ? (
            <div />
          ) : (
            <>
              <AssignUserButton
                sourceGroupId={id}
                unassignInstead={!!assignedUserId}
              />
              <div />
              <div />
              <Button success inverse={!assignedUserId} onClick={handleDone}>
                Hecha
              </Button>
              <Button danger inverse onClick={handleDelete}>
                Borrar
              </Button>
            </>
          )}
        </SourceGroupBar>
      </div>
    </Card>
  );
};
