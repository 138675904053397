import dayjs from "dayjs";
import "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";

dayjs.locale("es");
dayjs.extend(relativeTime);
dayjs.extend(utc);

const uppercaseFirst = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const parseDateGmt = (dateGmt?: string) => {
  if (!dateGmt) return "";

  const now = dayjs.utc();
  const date = dayjs.utc(dateGmt);

  return now.diff(date, "day") > 1
    ? date.local().format("DD/MM/YYYY")
    : uppercaseFirst(date.from(now));
};

export const currentTime = (now = undefined) =>
  dayjs.utc(now || undefined).toISOString();

export const timeSince = (isoString) => {
  const now = dayjs.utc();
  const date = dayjs.utc(isoString);

  return `${now.diff(date, "hour")} hora(s)`;
};

export const isAfter = (date, referenceDate) => {
  return dayjs(date).isAfter(dayjs(referenceDate));
};
