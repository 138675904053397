import styled from "@emotion/styled";

const colors = {
  success: "#00C851",
  primary: "#007bff",
  highlight: "#9c27b0",
  warning: "#ffbb33",
  danger: "#ff4444",
};

const tints = {
  success: "#007E33",
  primary: "#0069d9",
  highlight: "#6a0080",
  warning: "#FF8800",
  danger: "#CC0000",
};

const getCode = ({
  danger = false,
  warning = false,
  success = false,
  highlight = false,
}) => {
  if (danger) return "danger";
  if (highlight) return "highlight";
  if (warning) return "warning";
  if (success) return "success";
  return "primary";
};

const mainColor = (props) => colors[getCode(props)];
const tintColor = (props) => tints[getCode(props)];
const white = (_) => "white";

const inversion = (getColor, getInverseColor = white) => (props) =>
  props.inverse ? getInverseColor(props) : getColor(props);

export const Button = styled("button")`
  transition: background 0.25s, color 0.25s, border-color 0.25s;
  padding: ${({ small }: any) => (small ? "2px 4px" : "8px 12px")};
  font-size: ${({ small }: any) => (small ? "0.8em" : "0.9em")};
  border-radius: 3px;
  font-weight: 700;

  ${(props) => {
    if (props.disabled) {
      return `
        cursor: not-allowed;
        background: ${inversion(() => "#7d7d7d")(props)};
        color: ${inversion(white, () => "#4a4a4a")(props)};
        border: 2px solid #7d7d7d;
        `;
    }

    return `
    border: 2px solid ${mainColor(props)};
    color: ${inversion(white, mainColor)(props)};
    background: ${inversion(mainColor)(props)};
    cursor: ${props.onClick ? "pointer" : "default"};

  &.active,
  &:hover {
    background: ${inversion(tintColor, mainColor)(props)};
    color: ${inversion(white)(props)};
    border-color: ${inversion(tintColor, mainColor)(props)};
  }
  `;
  }}
`;

export const ButtonRow = styled("div")`
  display: flex;
  justify-content: space-between;
`;
