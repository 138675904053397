import { useState, useEffect } from "react";
import { postDeleteSourceFromGroup, postAddSourceToGroup } from "../fetchers";
import styled from "@emotion/styled";
import { Button } from "../Button";

const AddSourceForm = ({ sourceGroupId, setSources }) => {
  const [url, setUrl] = useState("");

  const handleAddSource = () => {
    postAddSourceToGroup({ sourceGroupId, url }).then(({ sources }) => {
      setSources(sources);
      setUrl("");
    });
  };

  return (
    <div
      style={{
        // border: "1px solid red",
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "16px",
      }}
    >
      <input
        type="text"
        value={url}
        onChange={(evt) => setUrl(evt.target.value)}
        placeholder="URL de la fuente"
        style={{
          marginRight: "2px",
          width: "100%",
          maxWidth: "300px",
        }}
      />
      <Button small inverse onClick={handleAddSource}>
        Añadir fuente
      </Button>
    </div>
  );
};

const PlainButton = styled("button")({
  border: 0,
  padding: 0,
  color: "red",
  textDecoration: "underline",
  "&:hover": {
    cursor: "pointer",
  },
});

const SourceStyle = styled("div")({
  display: "flex",
  alignItems: "center",
  // border: "1px solid green",
  marginBottom: "4px",
  img: {
    width: "200px",
    height: "115px",
    objectFit: "cover",
    display: "block",
    margin: "0 16px 0 0",
  },

  "@media screen and (max-width: 650px)": {
    flexDirection: "column",

    img: {
      width: "100%",
      height: "150px",
      margin: "0 0 16px",
    },
  },
});

const Source = ({ source, deleteSource, isEditable }) => {
  const { id, title, image, description, siteName, url } = source;

  return (
    <SourceStyle>
      <a
        href={url}
        target="_blank"
        rel="noopener"
        style={{
          flexShrink: 0,
          flexGrow: 0,
        }}
      >
        <img src={image} style={{}} />
      </a>
      <div>
        <a
          href={url}
          target="_blank"
          rel="noopener"
          style={{ textDecoration: "none" }}
        >
          <h2 style={{ margin: 0, color: "black", fontSize: "1.3em" }}>
            {title} <span style={{ fontSize: "0.8em" }}>({siteName})</span>
          </h2>
          {description && (
            <p style={{ margin: "8px 0", color: "#333" }}>
              {description.substring(0, 200)}
              {description.length > 200 ? "..." : ""}
            </p>
          )}
        </a>
        <div style={{ fontSize: "0.8em" }}>
          {isEditable && (
            <PlainButton onClick={() => deleteSource(id)}>Eliminar</PlainButton>
          )}
          <span style={{ color: "#999", marginLeft: "8px" }}>{id}</span>
        </div>
      </div>
    </SourceStyle>
  );
};

const CollapserStyle = styled("div")(({ isCollapsed }: any) => ({
  overflow: "hidden",
  position: "relative",
  maxHeight: "2000px",

  ...(isCollapsed
    ? {
        maxHeight: "60px",
      }
    : {}),
}));

const Collapser = ({ children }) => {
  const [isCollapsed, setCollapsed] = useState(true);
  return (
    <CollapserStyle isCollapsed={isCollapsed}>
      {children}
      {isCollapsed && (
        <>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,

              backgroundImage:
                "linear-gradient(to bottom, rgba(255,255,255,0.6) 0%, rgba(255,255,255,1))",
            }}
          ></div>
          <button
            onClick={() => setCollapsed(false)}
            style={{
              border: "1px solid #ccc",
              position: "absolute",
              bottom: "8px",
              left: "50%",
              right: "50%",
              background: "white",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Ver más fuentes
          </button>
        </>
      )}
    </CollapserStyle>
  );
};

export const Sources = ({
  sourceGroupId,
  sources: initialSources,
  isEditable,
}) => {
  const [sources, setSources] = useState(initialSources);
  const [firstSource, ...otherSources] = sources || [];

  useEffect(() => {
    setSources(initialSources);
  }, [initialSources]);

  const deleteSource = async (sourceId) => {
    const newSourceGroup = await postDeleteSourceFromGroup({
      sourceId,
      sourceGroupId,
    });
    setSources(newSourceGroup.sources);
  };

  return (
    <div>
      <div>
        {firstSource && (
          <Source
            source={firstSource}
            deleteSource={deleteSource}
            isEditable={isEditable}
          />
        )}
        {!!otherSources.length && (
          <Collapser>
            {otherSources.map((source) => (
              <Source
                key={source.id}
                source={source}
                deleteSource={deleteSource}
                isEditable={isEditable}
              />
            ))}
          </Collapser>
        )}
      </div>
      {isEditable && (
        <AddSourceForm sourceGroupId={sourceGroupId} setSources={setSources} />
      )}
    </div>
  );
};
